import React, { Fragment } from 'react';
import { graphql } from "gatsby"
import Layout from "../App/components/layout"
import SEO from "../App/components/seo"
import App from "../App/App"

import SignInForm  from '../components/SignIn';
import OnSendEmailVerificationButton from '../components/Session/onSendEmailVerification';
import { SignUpLink } from '../components/SignUp';
import { PasswordForgetLink } from '../components/PasswordForget';

import { AuthUserContext } from '../components/Session';


const IndexPage = ({data}, location) => (
  <Layout>
    <SEO title="CECA-ACEA MCP" />

    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? (
          authUser.emailVerified ? (
          <>
          <Fragment>
              <div className="wrapper">
                  <h1>Manuals</h1>
              </div>
                  <App array={data.craft.entries} categories={data.craft.manufact} type={data.craft.eletype} language={data.craft.langue} />

          </Fragment>
          </>
          ) : (
              <>
              <OnSendEmailVerificationButton/>
              </>
          )
        ) : (
            <Fragment>
              <SignInForm />
              <div className="wrapper maxWidth"><PasswordForgetLink /></div>
              <SignUpLink />
            </Fragment>
        )
      }
    </AuthUserContext.Consumer>
  </Layout>
)

export default IndexPage;
export const data = graphql`
query{
  craft{
    manufact: categories(group: manufacture, orderBy:"title asc" ){
      ...on Craft_ManufactureCategory{
        title
        uri
        asset{
          url
          id
        }
      }
    }
    eletype: categories(group: elevatorType, orderBy:"title asc"){
      ...on Craft_ElevatorTypeCategory{
        title
        asset{
          url
          id
        }
      }
    }
    langue: categories(group: language, orderBy:"title asc"){
      ...on Craft_LanguageCategory{
        title
      }
    }
    entries(type: Manuals, orderBy:"title asc"){
      ...on Craft_Manuals{
        title
        slug
        uri
        elevatorType{
          title
        }
        elevatorManufacturer{
          title
        }
        language{
          title
        }
        manual{
          url
        }
      }
    }
  }
}
`;
