import React from 'react';
import { Link } from 'gatsby';

class ManufatureIcons extends React.Component{
    constructor(props){
        super(props);
        this.manufatuerersArray = this.props.manufatuerersObject;
    }

    componentDidMount() {
        import('tiny-slider/src/tiny-slider.js').then(slider => {
            slider.tns({
                container: '.slideDisplay',
                items: 2.5,
                slideBy: "page",
                mouseDrag: true,
                swipeAngle: false,
                speed: 400,
                loop: false,
                controlsPosition: 'bottom',
                responsive: {
                    200: {
                        gutter: 10
                    },
                    500: {
                        items: 3.5
                    },
                    1000: {
                        items: 4.5
                    },
                    1100: {
                        items: 5.5
                    },
                    1250: {
                        items: 6.5
                    }
                }
            });
        })

        /*import('tiny-slider/src/tiny-slider.module.js')
        var slider = tns({
            container: '.slideDisplay',
            items: 2.5,
            slideBy: "page",
            mouseDrag: true,
            swipeAngle: false,
            speed: 400,
            loop: false,
            controlsPosition: 'bottom',
            responsive: {
                200: {
                    gutter: 10
                },
                500: {
                    items: 3.5
                },
                1000: {
                    items: 4.5
                },
                1100: {
                    disable: true
                }
            }
        });*/

    }

    render(){
        return(
            <div>
                <ul className="groupDisplay slideDisplay">
                {
                    this.manufatuerersArray.map( ( query, i ) => {
                    return (
                        <li key= {query.title + '' + i} >
                            <Link to={"/"+query.uri}>
                            {query.asset[0] ? <img src={query.asset[0].url} alt={query.title} /> : <></>}
                            {query.asset[0] ? <h2 className="visually-hidden">{query.title}</h2> : <h2>{query.title}</h2> }
                            </Link>
                        </li>
                    )
                    })
                }
                </ul>
            </div>
        )
    }
}

export default ManufatureIcons;
