import React from 'react';
//import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import Bookmark from '../components/Bookmark';

import { AuthUserContext } from '../components/Session';
//import { compose } from 'recompose';

import FilterInput from './components/filter';
//import ManualList from './components/ManualList';
import ManufatureIcons from './components/manufatureIcons';
import TypeList from './components/Typelist';
//import * as ROUTES from '../constants/routes';
import'../styles/index.module.scss';

class App extends React.Component {
    constructor(props){
        super(props);

        const initalState = Object.keys(props.array).map(i => props.array[i]);

        this.state = {
            array: initalState
        }


    }

    onFilterChange(filter){
        this.setState({
            array: filter
        })
    }


    render(){
        return(

            <div>
                <div className="wrapper">
                    <FilterInput array={this.state.array.entries} manufacture={this.props.categories} eletype={this.props.type} language={this.props.language} onFilterChange={this.onFilterChange.bind(this)}/>
                </div>
                <div className="grouping right-overflow">
                    <h4>Browse by Manufacturer</h4>
                    <ManufatureIcons manufatuerersObject={this.props.categories}/>
                </div>
                <div className="grouping">
                    <h4>Browse by Tag Type</h4>
                    <TypeList typeObject={this.props.type} array={this.state.array}/>
                </div>
                <div className="grouping">
                    <h4>Saved Manuals</h4>
                    <AuthUserContext.Consumer>
                      {authUser =>
                        authUser ? (
                          <>
                            {Object.keys(authUser.favorites).map(i => Object.keys(authUser.favorites)[i])}

                             <Bookmark title={Object.keys(authUser.favorites).title} array={authUser.favorites}/>
                          </>
                      ) : (
                        <> You need to <Link to="/signin">log in</Link> to see restricted
                         content</>
                      )
                    }
                  </AuthUserContext.Consumer>
                </div>
            </div>


        );
    }
}

export default App;


/*
            <div>
                <p>Home link is {this.state.homeLink}</p>
                <Age initialAge={1} greet={this.onGreet} changeLink={this.onChangeLinkName.bind(this)}/>
            </div


                                <ol>
                    {
                    this.state.array.map( (query ) => {
                        return (
                        <li>
                            <h2>{query.title}</h2>
                        </li>
                        )
                    })
                    }
                    </ol>



            >*/
