import React from 'react';
import { Link } from 'gatsby'
//import TinySlider from 'tiny-slider-react'



class TypeList extends React.Component{
    constructor(props){
        super(props);
        console.log("typeList", props.array)
        this.typeArray = this.props.typeObject;
    }

    componentDidMount() {

        this.typeArray = this.typeArray.sort();


        /*var slider = tns({
            container: '.cards',
            items: 2.5,
            slideBy: "page",
            mouseDrag: true,
            swipeAngle: false,
            speed: 400,
            loop: false,
            controlsPosition: 'bottom',
            responsive: {
                200: {
                    gutter: 10
                },
                500: {
                    items: 3.5
                },
                600: {
                    disable: true
                }
            }
        });*/
    }

    render(){
        /*const settings ={
            lazyload: true,
            nav: false,
            mouseDrag: true
        };*/
        return(
            <div>
                <ul className="cards groupCards">
                {
                    this.props.typeObject.map( ( query, i ) => {
                    return (
                                <li className="type" key={query.title + '' + i }>
                                    <article className="card typeCard">
                                        <Link to='/manuals' state={{elevatorType: query.title}}>
                                            <h2>{query.title}</h2>
                                        </Link>
                                    </article>
                                </li>


                    )
                    })
                }
                </ul>
            </div>
        )
    }
}

export default TypeList;
